import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { Formik, FieldArray, Form, Field } from "formik";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		borderRadius: "22.5px",
		marginRight: theme.spacing(1),
		flex: 1,
	},

	extraAttr: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));

const ContactSchema = Yup.object().shape({
	name: Yup.string()
		.min(10, "Porfavor, digite seu nome completo")
		.max(250, "Você atingiu limite de caracteres válidos")
		.required("Campo de preenchimento necessário"),
	number: Yup.string().min(8, "Porfavor, digite o seu whatsapp corretamente 55219999-9999").max(50, "Porfavor, digite seu whatsapp corretamente 55219999-9999"),
	email: Yup.string().email("Porfavor, digite um email válido!"),
});

const FormularioModal = ({ open, onClose, contactId, initialValues, onSave }) => {
	const classes = useStyles();
	const isMounted = useRef(true);

	const initialState = {
		name: "",
		number: "",
		email: "",
	};

	const [contact, setContact] = useState(initialState);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		const fetchContact = async () => {
			if (initialValues) {
				setContact(prevState => {
					return { ...prevState, ...initialValues };
				});
			}

			if (!contactId) return;

			try {
				const { data } = await api.get(`/contacts/${contactId}`);
				if (isMounted.current) {
					setContact(data);
				}
			} catch (err) {
				toastError(err);
			}
		};

		fetchContact();
	}, [contactId, open, initialValues]);

	const handleClose = () => {
		onClose();
		setContact(initialState);
	};

	const handleSaveContact = async values => {
		try {

			const { data } = await api.post("/contacts_promo", values);
			if (onSave) {
				onSave(data);
			}
			handleClose();
			
			toast.success(i18n.t("contactModal.success"));
		} catch (err) {
			toastError(err);
		}
	};

	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
				<Formik
					initialValues={contact}
					enableReinitialize={true}
					validationSchema={ContactSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveContact(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, errors, touched, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<Field
									as={TextField}
									label="Nome Completo"
									name="name"
									autoFocus
									error={touched.name && Boolean(errors.name)}
									helperText={touched.name && errors.name}
									variant="outlined"
									placeholder="EX: Jhon Sebastian"
									fullWidth
									margin="dense"
									className={classes.textField}
								/>
								<Field
									as={TextField}
									label="Whastapp/Telefone"
									name="number"
									error={touched.number && Boolean(errors.number)}
									helperText={touched.number && errors.number}
									placeholder="EX: 55139123-44321"
									fullWidth
									variant="outlined"
									margin="dense"
								/>
								<div>
									<Field
										as={TextField}
										label="Email"
										name="email"
										error={touched.email && Boolean(errors.email)}
										helperText={touched.email && errors.email}
										placeholder="EX: jhon@gmail.com"
										fullWidth
										margin="dense"
										variant="outlined"
									/>
								</div>
								
								<div>
									<Field
										as={TextField}
										label="Possui algum veículo? Digite a placa do veículo"
										name="veiculo_placa_1"
										placeholder="EX: ZHDG987"
										fullWidth
										margin="dense"
										variant="outlined"
										className={classes.textField}
									/>
								</div>


							</DialogContent>
							<center><DialogActions>

								

									<Button
									style={{ margin: "0 auto", width: "100%",  borderRadius: "22.5px", backgroundColor:"#09AF26", color:"#ffffff" }}
									type="submit"
									disabled={isSubmitting}
									fullWidth
									variant="contained"
									className={classes.btnWrapper}>
									   ENVIAR MEUS DADOS PARA A SOMATTO!
								    </Button>

									

							</DialogActions>
							</center>	
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default FormularioModal;
