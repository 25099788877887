import React, {useState, useContext} from "react";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import FormularioModal from "../../components/FormularioModal";
import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid"; 
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import { AuthContext } from "../../context/Auth/AuthContext";
import logo from "../../assets/logo.png";


const Copyright = () => {
  return (
    <Typography variant="body2" color="primary" align="center">
      {"Copyright "}
      <Link color="primary" href="#">
        SOMATTO.ORG.BR | 
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
 };

const reducer = (state, action) => {
  if (action.type === "LOAD_CONTACTS") {
    const contacts = action.payload;
    const newContacts = [];

    contacts.forEach((contact) => {
      const contactIndex = state.findIndex((c) => c.id === contact.id);
      if (contactIndex !== -1) {
        state[contactIndex] = contact;
      } else {
        newContacts.push(contact);
      }
    });

    return [...state, ...newContacts];
  }

  if (action.type === "UPDATE_CONTACTS") {
    const contact = action.payload;
    const contactIndex = state.findIndex((c) => c.id === contact.id);

    if (contactIndex !== -1) {
      state[contactIndex] = contact;
      return [...state];
    } else {
      return [contact, ...state];
    }
  }

  if (action.type === "DELETE_CONTACT") {
    const contactId = action.payload;

    const contactIndex = state.findIndex((c) => c.id === contactId);
    if (contactIndex !== -1) {
      state.splice(contactIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};
const useStyles = makeStyles(theme => ({
	root: {
		width: "100vw",
		height: "120vh",
		backgroundImage: "url(https://somatto.org.br/wp-content/uploads/2023/12/bg-2_.jpg)",
		backgroundRepeat: "no-repeat",
		backgroundSize: "100% 100%",
		backgroundPosition: "center",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		textAlign: "center",
	},
	paper: {
		backgroundColor: theme.palette.login, //DARK MODE PLW DESIGN//
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		padding: "55px 30px",
		borderRadius: "12.5px",
    width:"100%",
    maxWidth:"600px"
	},
	avatar: {
		margin: theme.spacing(1),  
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	powered: {
		color: "white"
	}
}));


const Formulario = () => {
  const classes = useStyles();
  const [user, setUser] = useState({ email: "", password: "" });
  const { handleLogin } = useContext(AuthContext);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [contactModalOpen, setContactModalOpen] = useState(false);



	const handleChangeInput = e => {
		setUser({ ...user, [e.target.name]: e.target.value });
	};

	const handlSubmit = e => {
		e.preventDefault();
		handleLogin(user);
	};

  const handleOpenContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(false);
  };


  return (


    <div className={classes.root}>
    <Container component="main" maxWidth="xs">
      <CssBaseline/>
      <div className={classes.paper}>
        <div>
          <img style={{ margin: "0 auto", width: "100%" }} src={logo} alt="Whats" />
        </div>

        <div>
          <p>
            <h1 style={{ margin: "0 auto", width: "100%", fontWeight:"bold", color: "#213bff", fontSize:"27px" }}>A MELHOR ASSOCIAÇÃO DE PROTEÇÃO VEICULAR</h1>
          </p>
        </div>
        

        <div>
          <p>
            <h1 style={{ margin: "0 auto", width: "100%",  color: "grey", fontSize:"16px" }}>Agimos com transparência, ética e segurança para você e seu veículo, visando sempre o melhor valor e qualidade em nosso portfólio de benefícios.</h1>
          </p>
        </div>        
        <MainContainer className={classes.mainContainer}>

          <FormularioModal
            open={contactModalOpen}
            onClose={handleCloseContactModal}
            aria-labelledby="form-dialog-title"
            contactId={selectedContactId}
          ></FormularioModal>


              <center>
                  <Button
                  style={{ margin: "0 auto", width: "100%",  borderRadius: "22.5px", backgroundColor:"#09AF26", color:"#ffffff" }}
                  fullWidth
                  variant="contained"
                  className={classes.submit}
                  onClick={handleOpenContactModal}>
                  Cadastre-se na Somatto
                  </Button>
              </center> 

          </MainContainer>
      
      </div>
      
      
      <Box mt={8} >{ <Copyright /> }</Box>


      
    </Container>
    </div>


  );
};

export default Formulario;
